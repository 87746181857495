* {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.75;
  color: #404040;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ----------- APP ----------- */

.app-main {
  margin: 0 20%;
  padding-bottom: 60px;
}

.App {
  text-align: center;
  padding-top: 15vh;
  padding-bottom: 100px;
  overflow: auto;
  min-height: 100vh;
  position: relative;
}

h1 {
  color: #fff;
}

p {
  margin: 16px 0px;
}

a {
  color: #0066cc;
}

a:hover {
  color: #33A0FF;
}

.section-header {
  text-align: left;
  border-bottom: 1px solid #ccc;
  margin-bottom: 48px;
}

/* ----------- NAV ----------- */

.nav {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  padding: 0 24px;
  z-index: 10;
  height: 15vh;
  background-color: #fff;
  text-decoration: none;
}

.nav-right a {
  color: #999;
  font-weight: 600;
  width: 200px;
  padding: 12px;
  margin: 12px;
}

.nav-right a:hover:nth-child(n+1):nth-child(-n+3) {
  border-bottom: 2px solid #4d4d4d;
  transition: .25s;
}

.nav-right .active:nth-child(n+1):nth-child(-n+3) {
  border-bottom: 2px solid #4d4d4d;
}

.logo {
  height: 100px;
  z-index: -1;
  text-decoration: none;
}

.nav-link {
  padding: 0 12px;
  text-decoration: none;
}

.nav .nav-button {
  color: #fff;
  background-color: #009999;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
}

.hamburger {
  color: #404040;
  position: absolute;
  padding: 0 2%;
  vertical-align: middle;
  font-size: 1.5em;
  right: 10px;
}

.dropdown {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px #4d4d4d;
  z-index: 1;
  right: 0;
  margin: 0 2%;
}

.hamburger:hover .dropdown {
  display: block;
}

.dropdown a {
  padding: 10%;
  color: #4D4D4D;
  font-size: .9em;
  display: block;
  text-decoration: none;
}
.dropdown a:hover {
  background-color: #E6E6E6;
}

.dropbtn {
  border: none;
  outline: none;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

/* ----------- LANDING PAGE ----------- */

.landing-page-main {
  width: 100%;
  padding: 5% 0;
}

.landing-page-main h1 {
  color: #404040;
  margin: 0;
}

.landing-page-main .arrow {
  display: block;
  margin-right: auto;
  margin-left: auto;
  height: 30px;
  padding-top: 100px;
}

.landing-page-pic-container {
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  align-items: center;
  padding: 3% 0;
}

.landing-page-pic-item {
  padding: 1.5%;
}

.landing-page-pic-item:first-child {
  margin: 0 0 64px 0;
  background: #f2f2f2;
}
.landing-page-pic-item:first-child p {
  margin: 24px;
}
.fa-chevron-circle-right {
  color: #009999;
  margin-right: 8px;
}

/* meter {
  width: 300px;
  border: #f2f2f2;
}
.meter-value {
  opacity: 0;
  position: relative;
  top:-28px;
}
.meter-value:hover {
  opacity: 1;
}
meter::-webkit-meter-bar {
  background: #e6e6e9;
}
meter::-webkit-meter-optimum-value {
  background: #33A0FF;
} */

/* meter::after {
  content: attr(value)'%';
  position: relative;
  top:-18px;
} */

#lp-pic-1 h2 {
  color: #fff;
}

#lp-pic-1 {
  background: linear-gradient(to right, #0066cc, #009999);
}
.lp-second-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 64px;
  width: 80%;
}
#lp-pic-2,
#lp-pic-3 {
  background-position: center;
  background-size: cover;
  width: 45%;
  height: 250px;
  margin-top: 1%;
}

#lp-pic-2 {
  background-image: url('../../Images/card-fundraiser.jpg');
}

#lp-pic-3 {
  background-image: url('../../Images/banner-wxii.jpg');
}

#lp-pic-2 h2,
#lp-pic-3 h2,
#lp-pic-2 a,
#lp-pic-3 a {
  color: #fff;
  margin-top: 100px;
}

.landing-page-button {
  height: 50px;
  background-color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  padding: 0 32px;
  margin: 8px;
}

.fa-chevron-right {
  font-size: smaller;
  vertical-align: middle;
}

#lp-btn-1 {
  color: #0080ff;
  border: 1px solid #0080ff;
  margin: 28px 8px;
}

#lp-btn-1:hover {
  color: #fff;
  background-color: #0066cc;
  border: 1px solid #0066cc;
  transition: .3s;
}

#lp-btn-2 {
  color: #fff;
  background-color: #0080ff;
  margin: 28px 8px;
}

#lp-btn-2:hover {
  background-color: #0066cc;
  transition: .3s;
}

.virtual-5k-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.vfk-pic {
  max-width: 300px;
  max-height: 350px;
  object-fit: cover;
  margin: 4px;
}

/* ----------- KEN ----------- */

.main-ken-pic {
  max-width: 70%;
  max-height: 350px;
}

.ken-timeline {
  padding: 0;
}
.ken-timeline .ken-year,
.ken-timeline p {
  text-align: left;
}

.ken-pic {
  max-width: 70%;
  max-height: 350px;
  object-fit: cover;
}

.ken-year {
  font-size: 1.5em;
  margin-bottom: 0;
}

.ken li {
  list-style: none;
}

.ken li p {
  margin: .5em 0;
}

/* ----------- PMP ----------- */

.pmp-main {
  text-align: left;
}

.pmp-fast-facts {
  margin-top: 24px;
  padding: 12px 24px;
  border-radius: 8px;
  background-color: #88bef5;
}

/* ----------- ABOUT ----------- */

.about-main {
  text-align: left;
}

/* ----------- FAQ ----------- */

.faq-container {
  align-items: center;
  font-size: 2em;
  color: #404040;
}

.faq-container .fa-wrench {
  text-align: center;
}

/* ----------- FUNDRAISERS ----------- */

.fundraiser-item > ul > li > h3 {
  text-decoration: underline;
}

.cardSample {
  width: 50%;
  margin: 0 25%;
  max-width: 300px;
}

/* ----------- GET INVOLVED ----------- */

.get-involved-item,
.fundraiser-item {
  text-align: left;
}

/* ----------- CONTACT ----------- */

.contact-form {
  text-align: left;
}

.contact-item {
  padding: 3%;
}

/* ----------- FOOTER ----------- */

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
}

.footer p {
  left: 10px;
  margin: 0;
}

.links {
  display: flex;
  justify-content: center;
}

.links p {
  font-size: 24px;
  padding: 0 2%;
}

button:hover {
  cursor: pointer;
}

/* ----------- DONATE ----------- */

.donate-container button {
  margin: 2% 0;
}

.separator {
  border-bottom: 1px solid black;
  margin: 0 20%;
}

.donate-btn {
  color: #fff;
  background-color: #009999;
  border: none;
  border-radius: 4px;
  padding: 16px 32px;
  font-weight: 600;
}

.donate-btn:hover,
.nav a > .nav-button:hover {
  color: #fff;
  background-color: #008080;
  transition: .3s;
}

.donate-item {
  margin: 48px 0px;
  padding: 24px;
  background-color: #F2F2F2;
  border-radius: 8px;
}

@media screen and (max-width:764px) {
  .app-main {
    margin: 0 5%;
  }
  .logo {
    height: 75px;
  }
  .nav-right {
    display: none;
  }
  .lp-second-container {
    flex-direction: column;
    padding: 1%;
  }
  #lp-pic-2,
  #lp-pic-3 {
    width: 100%;
  }
}

@media screen and (min-width: 765px) {
  .hamburger {
    display: none;
  }
}

@media screen and (min-width: 1025px) {
  .virtual-5k-container {
    padding: 0 10%;
  }
}